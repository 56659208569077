"use client";
import React from "react";
import { IKImage, IKContext } from "imagekitio-react";
import Image from "next/image";
interface ImageKitProps {
  path: string;
  transformation?: any;
  className?: string;
  style?: any;
}

const ImageKit = ({ path, transformation, className, style }: ImageKitProps) => {
  return (
    <IKContext publicKey="public_VIG52kvilewBgnHJQrpVxOdswS8=" isPrivateFile urlEndpoint="https://ik.imagekit.io/qug8x8xy2/lucky" transformationPosition="path" authenticationEndpoint="http://www.luckynetwork.net/auth">
      <IKImage path={path} transformation={transformation} lqip={{ active: true, quality: 80, blur: 1 }} className={className} loading="lazy" style={style} />
    </IKContext>
  );
};

export default ImageKit;
