import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/frontend/LuckyNetwork/LuckyNetwork-LandingWebV5/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/frontend/LuckyNetwork/LuckyNetwork-LandingWebV5/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/frontend/LuckyNetwork/LuckyNetwork-LandingWebV5/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/home/frontend/LuckyNetwork/LuckyNetwork-LandingWebV5/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/frontend/LuckyNetwork/LuckyNetwork-LandingWebV5/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/home/frontend/LuckyNetwork/LuckyNetwork-LandingWebV5/public/img/landing/footer/luckynetwork_footerlogo.png");
;
import(/* webpackMode: "eager" */ "/home/frontend/LuckyNetwork/LuckyNetwork-LandingWebV5/public/img/landing/LN_HERO_RIGHT.webp");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/frontend/LuckyNetwork/LuckyNetwork-LandingWebV5/src/components/landing/navigation/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/frontend/LuckyNetwork/LuckyNetwork-LandingWebV5/src/components/landing/plugins/Imagekit.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["OnlinePlayers"] */ "/home/frontend/LuckyNetwork/LuckyNetwork-LandingWebV5/src/components/landing/section/SectionOnlinePlay.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/frontend/LuckyNetwork/LuckyNetwork-LandingWebV5/src/components/landing/ui/HowToPlayUI.tsx");
