"use client";
import React, { useState, useEffect } from "react";
import { ServerStatus } from "@/types/player";

async function getOnlinePlayers() {
    const res = await fetch("/api/playeronline", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    });
    if (!res.ok) {
        return new Error("An error occurred while fetching the data.");
    }
    const data = await res.json();
    return data.data;
}

export function OnlinePlayers() {
    const [onlinePlayers, setOnlinePlayers] = useState<ServerStatus | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error | null>(null);

    async function fetchData() {
        const data = await getOnlinePlayers();
        setLoading(true);
        if (data instanceof Error) {
            setError(data);
        }
        setOnlinePlayers(data);
        setLoading(false);
    }

    useEffect(() => {
        fetchData(); // Call the function immediately

        const intervalId = setInterval(fetchData, 300000); // 5 minutes in milliseconds

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    return (
        <>
            {/* loading */}
            {onlinePlayers?.online == true ? (
                <>
                    <div className=" font-nexabold">
                        {/* <span className="text-green-400 text-2xl flex items-center gap-4 text-center justify-center"> */}
                        <span className="text-green-400 text-2xl flex items-center text-center lg:text-start lg:justify-start justify-center gap-4">
                            {" "}
                            {onlinePlayers.players.now} / {onlinePlayers.players.max} Online{" "}
                            <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle
                                    cx="7"
                                    cy="7"
                                    r="7"
                                    fill="#27D261"
                                />
                            </svg>
                        </span>
                    </div>
                </>
            ) : (
                <span className="text-red-400 text-2xl flex items-center gap-4"> 
                    {onlinePlayers?.online == false && (
                        <>
                            <div className="font-nexabold text-2xl">Offline</div>
                            <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle
                                    cx="7"
                                    cy="7"
                                    r="7"
                                    fill="#FF0000"
                                />
                            </svg>
                        </>
                    )}
                    {loading && (
                        <>
                            <div>Loading...</div>
                        </>
                    )}

                    {error && (
                        <>
                            <div className="text-wh ite">Error: {error.message}</div>
                        </>
                    )}
                </span>
            )}
        </>
    );
}
